import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import StaticHero from "@components/staticHero"
import Seo from "@components/seo"
import PrimaryCta from "@components/PrimaryCta"

// Assets
import { Link } from "gatsby"

const StayConnectedThankYouPage = ({ location }) => {
  return (
    <Layout location={location} headerType="narcolepsy" footerType="narcolepsy">
      <Seo title="Thank You for Signing Up | XYWAV®" location={location} />
      <StaticHero
        eyebrow="Sign me up"
        footnotes={
          <>
            <span tw="block pb-8">
              Keep an eye out for helpful information about narcolepsy.
            </span>
            <div tw="w-full border-b border-white"></div>
            <span tw="block py-8 ">
              Share some additional information about yourself so that we can
              send you relevant and helpful information.
            </span>

            <PrimaryCta
              url={`https://cloud.updates.jazzpharma.com/gettingtoknowyou/?ExternalId=${location?.state?.guid}&TriggerCode=XVNCBR`}
              isExternal={true}
              noInterstitial={true}
              isWhite
              width={tw`max-w-[21.25rem] lg:max-w-[21.875rem]`}
              height={tw`h-[2.625rem] lg:min-h-[3.4375rem]`}
              addedStyles={tw`font-black`}
            >
              Tell Us more about yourself
            </PrimaryCta>
            <div tw="pt-8 pb-6">
              <Link to="/narcolepsy" tw="font-extrabold underline">
                Return Home
              </Link>
            </div>
          </>
        }
        gradient="purple-red"
      >
        <>Thank you for signing up!</>
      </StaticHero>
    </Layout>
  )
}

export default StayConnectedThankYouPage
